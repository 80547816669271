/* global nx */
// called to populate item cards inserted after page load
export const hydrateCards = selector => {
  if (window.etdp) {
    // user has ETD, but we don't want to use that one. 
    // If we use the same one, it can cause a race,
    //  and the targets get overwritten before the response comes back.
    // create another one for temporary use
    const tempEtd = new window.etdInterface();
    tempEtd.getAllTargets(selector);
    tempEtd.updateAllTargets();
  }
  if (nx.prices.PriceLoader) {
    nx.prices.PriceLoader.initializeElements(selector);
  }
  if (window.listLoader) {
    // window.listLoader.initializeHearts('.alt_' + prodcode);
    window.listLoader.initializeHearts(selector);
  }
  // AJAX add to cart
  const atc = new nx.addToCart.AddToCartForm(selector);
  nx.prices.watchPriceBreaks(selector);
};
