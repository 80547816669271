import {
  toggleClassName,
  addClassName,
  readDatalayer,
  removeClassName
} from '../ute/utils';
import { furnitureFreight } from './furnitureFreight';
import { watchPriceBreaks } from '../prices/watchPriceBreaks';
import { getGwPrice } from '../prices/gwPrice';
import { wrapK2Message } from '../ute/k2message';

const initTrim = () => {
  if (typeof String.prototype.trim !== 'function') {
    String.prototype.trim = function () { //eslint-disable-line no-extend-native
      return this.replace(/^\s+|\s+$/g, '');
    }
  }
}

var variant = function variant() { };

/* Initialize globals, add onclick handler to attribute values, and set up
* initial filters */
variant.initialize = function initialize(displayprices, products, custom_required, custom_label) {
  // exit cleanly if this is not the variant material page
  if (typeof products == "undefined") {
    return;
  }
  //add tooltip to the Add to cart button
  document.querySelectorAll('#pd_submit').forEach(function (elem) {
    elem.myOpentip = new window.Opentip(elem, { style: 'blue' });
    elem.myOpentip.setContent("Pick the features you want before adding this item.");
  });

  variant.displayprices = displayprices;
  variant.products = products;
  variant.custom_required = custom_required;
  variant.custom_label = custom_label;
  initTrim();

  document.querySelectorAll('span.variant_attribute_value').forEach(function (el) {
    el.onclick = function () {
      variant.attributeValueClicked(el);
    };
  });

  document.querySelectorAll('select.variant_attribute_value_select').forEach(function (el) {
    el.onchange = function () {
      variant.attributeValueChanged(el);
    };
  });

  variant.getActiveFilters();
  variant.setCandidatesAndValidFilterValues();
  // If preselections dont match anything, clear them and show clean slate
  if (variant.candidates.length == 0) {
    variant.activeFilters = {};

    document.querySelectorAll('.variant_attribute_value_active').forEach(function (attr) {
      removeClassName(attr, 'variant_attribute_value_active');
    });

    variant.setCandidatesAndValidFilterValues();
  }

  variant.updateImageAndPrice();

  const candidates_price = document.getElementById('candidates_price');
  if (variant.displayprices && candidates_price) {
    candidates_price.style.visibility = 'visible';
  }
}

/* Prompt the user to refine their selected if they click Add to Cart before
* refining to a single material */
variant.submitClicked = function submitClicked() {
  if (variant.candidates.length == 1 && (!variant.custom_required || document.getElementById('customtext').value.length > 0)) {
    return true;
  } else {
    const variantSubmitFailed = document.getElementById('variant-submit-failed');
    if (variantSubmitFailed) {
      variantSubmitFailed.style.display = 'flex';
    }
    return false;
  }
}

/* Update page when an attribute value is changed */
variant.attributeValueChanged = function attributeValueChanged(selectObject) {
  if (selectObject.value == '') {
    variant.attributeValueClicked(selectObject.querySelectorAll('.variant_attribute_value_active')[0]);
  } else {
    variant.attributeValueClicked(document.getElementById(selectObject.value));
  }
  selectObject.blur();
}

/* Update page when an attribute value is clicked */
variant.attributeValueClicked = function attributeValueClicked(clicked_attribute_value) {
  var attr, attribute_name, attribute_value;
  // Toggle state of the attribute vales that was clicked
  toggleClassName(clicked_attribute_value, 'variant_attribute_value_active');
  variant.getActiveFilters(clicked_attribute_value);
  variant.setCandidatesAndValidFilterValues();

  if (variant.candidates.length == 0) {
    attr = variant.parseAttributeValueId(clicked_attribute_value.id);
    attribute_name = attr.attribute_name;
    attribute_value = attr.attribute_value;

    // We have no candidates that match the current filter combination.
    // De-activate all filters expect the one that was just clicked
    variant.activeFilters = {};
    variant.activeFilters[attribute_name] = attribute_value;

    variant.setCandidatesAndValidFilterValues();

    document.querySelectorAll('.variant_attribute_value_active').forEach(function (vattr) {
      if (vattr.id != clicked_attribute_value.id) {
        variant.unsetValue(vattr);
      }
    });

    // Re-run the click handler without any other filters
    variant.setCandidatesAndValidFilterValues();

    // If there are still no candidates, disable all filters
    if (variant.candidates.length == 0) {
      variant.activeFilters = {};

      document.querySelectorAll('.variant_attribute_value_active').forEach(function (vattr) {
        variant.unsetValue(vattr);
      });

      variant.setCandidatesAndValidFilterValues();
    }
  }

  variant.updateImageAndPrice();
}

variant.unsetValue = function unsetValue(vattr) {
  removeClassName(vattr, 'variant_attribute_value_active');
  if (vattr.parentElement.tagName == 'SELECT' && vattr.parentElement.value == vattr.id) {
    vattr.parentElement.selectedIndex = 0;
  }
}

/* Check which attribute values are active and updates the "activeFilters"
* hash. Active filters have the "variant_attribute_value_active" class.
* The clicked_attribute_value is optional, and if supplied, other values for
* that attribute will be deselected. (eg: If Size.M was clicked, Size.S &
* Size.L will be de-activated) */
variant.getActiveFilters = function getActiveFilters(clicked_attribute_value) {
  var attr, attribute_name, attribute_value;
  variant.activeFilters = {};

  if (typeof clicked_attribute_value != 'undefined') {
    attr = variant.parseAttributeValueId(clicked_attribute_value.id);
    attribute_name = attr.attribute_name;
    attribute_value = attr.attribute_value;
  }

  document.querySelectorAll('.variant_attribute_value_active').forEach(function (vattr) {
    var vattribute_name, vattribute_value;
    // unset active for other values of this attribute
    // don't modify the clicked one
    if (typeof clicked_attribute_value != 'undefined' && vattr.id != clicked_attribute_value.id && vattr.id.lastIndexOf(attribute_name, 0) == 0) {
      variant.unsetValue(vattr);
    } else {
      if (!vattr.id) {
        return; /* Skip */
      }
      // add anything that is staying clicked to our filter criteria
      vattr = variant.parseAttributeValueId(vattr.id);
      vattribute_name = vattr.attribute_name;
      vattribute_value = vattr.attribute_value;

      variant.activeFilters[vattribute_name] = vattribute_value;
    }
  });
}

/* Populate the variant.candidates array with product that satisfy the current
* variant.activeFilters criteria. Also sets variant.validFilterValues with
* values that are available amongst the current variant.candidates */
variant.setCandidatesAndValidFilterValues = function setCandidatesAndValidFilterValues() {
  variant.resetCandidates();
  Object.keys(variant.products).forEach(function (prodcode) {
    var valid_candidate = true;
    Object.keys(variant.activeFilters).forEach(function (k) {
      if (variant.activeFilters[k] != variant.products[prodcode].attributes[k]) {
        valid_candidate = false;
      }
    });
    if (valid_candidate) {
      variant.addCandidate(prodcode);
    }
  });

  // set attribute label for selected/unselected values
  document.querySelectorAll('.variant_attribute_name').forEach(function (attr) {
    var a, attribute_name, attribute_value, attribute_value_icon;
    if (!attr.id) {
      return; /* Skip */
    }
    a = attr.id.match(/^attribute_(.+)$/);
    attribute_name = a[1];

    if (variant.activeFilters[attribute_name]) {
      attribute_value_icon = document.getElementById(attribute_name + '.' + variant.activeFilters[attribute_name] + '.icon');
      if (attribute_value_icon) {
        attribute_value = attribute_value_icon.title;
      } else {
        attribute_value = document.getElementById(attribute_name + '.' + variant.activeFilters[attribute_name]).innerHTML;
      }
      attr.innerHTML = attribute_name + ': ' + attribute_value;
    } else {
      attr.innerHTML = attribute_name;
    }

    variant.setValidFilterValuesForAttribute(attribute_name);
  });

  // grey out unavailable options
  document.querySelectorAll('.variant_attribute_value').forEach(function (attr) {
    if (variant.isAttributeValueAvailable(attr.id)) {
      removeClassName(attr, 'variant_attribute_value_unavailable');
      variant.setAttrTips(attr, false);
    } else {
      addClassName(attr, 'variant_attribute_value_unavailable');
      variant.setAttrTips(attr, true);
    }
  });

  // toggle classes for the material list, only available
  // when an ecadmin user is logged in
  document.querySelectorAll('.variant_refinement_preview').forEach(function (row) {
    var prodcode = row.firstChild.innerHTML;

    if (variant.candidates.indexOf(prodcode) != -1) {
      removeClassName(row, 'variant_refinement_preview_unavailable');
      addClassName(row, 'variant_refinement_preview_available');
    } else {
      removeClassName(row, 'variant_refinement_preview_available');
      addClassName(row, 'variant_refinement_preview_unavailable');
    }
  });
}

variant.setAttrTips = function setAttrTips(elem, activate) {
  var exists = (typeof elem.myOpentip != 'undefined');
  if (activate && exists) {
    elem.myOpentip.activate();
  } else if (activate && !exists) {
    elem.myOpentip = new window.Opentip(elem);
    elem.myOpentip.setContent('Not available with current selection');
  } else if (!activate && exists) {
    elem.myOpentip.deactivate();
  }
}

/* (Re-)Initialises variant.candidates & variant.validFilterValues */
variant.resetCandidates = function resetCandidates() {
  variant.candidates = [];
  variant.candidatesMinPrice = undefined;
  variant.candidatesMinDecicents = undefined;
  variant.candidatesMaxPrice = undefined;
  variant.candidatesMaxDecicents = undefined;
  variant.validFilterValues = {};
  variant.defaultStock = "";
}

/* Adds a product to variant.candidates and adds it's attribute values to
* variant.validFilterValues */
variant.addCandidate = function addCandidate(prodcode) {
  variant.candidates.push(prodcode);

  /* Check if this products price changes the existing min or max price */
  var price = variant.products[prodcode].price;
  var decicents = variant.products[prodcode].decicents;
  var htmlStock = variant.products[prodcode].stock_info;

  if (typeof decicents == "undefined") {
    return;
  }

  if (typeof variant.candidatesMinDecicents == "undefined" || variant.candidatesMinDecicents > decicents) {
    variant.candidatesMinPrice = price;
    variant.candidatesMinDecicents = decicents;
  }

  if (typeof variant.candidatesMaxDecicents == "undefined" || variant.candidatesMaxDecicents < decicents) {
    variant.candidatesMaxPrice = price;
    variant.candidatesMaxDecicents = decicents;
  }
  //get the default stock
  // stock = htmlStock.match(/>(.*)</).pop();
  if (htmlStock.indexOf('in stock')) {
    variant.defaultStock = '<span class="pdp_tick ">In stock</span>';
  } else if (htmlStock.indexOf('Available') && variant.defaultStock.indexOf('In stock') == -1) {
    variant.defaultStock = '<span class="pdp_tick ">Available to order</span>';
  } else if (variant.defaultStock.indexOf('Available to order') == -1 && variant.defaultStock.indexOf('In stock') == -1) {
    variant.defaultStock = htmlStock;
  }
}

/* Check the available values for an attribute with respect to selected values
* for other attributes. (eg. if the selected colour makes certain sizes unavailable */
variant.setValidFilterValuesForAttribute = function setValidFilterValuesForAttribute(attribute_name) {
  var otherFilters = [];
  var candidates = [];
  Object.keys(variant.activeFilters).forEach(function (filter_attribute) {
    if (filter_attribute != attribute_name) {
      otherFilters[filter_attribute] = variant.activeFilters[filter_attribute];
    }
  });

  Object.keys(variant.products).forEach(function (prodcode) {
    var valid_candidate = true;
    var attribute_value;
    Object.keys(otherFilters).forEach(function (k) {
      if (otherFilters[k] != variant.products[prodcode].attributes[k]) {
        valid_candidate = false;
      }
    });
    if (valid_candidate) {
      if (typeof variant.validFilterValues[attribute_name] == "undefined") {
        variant.validFilterValues[attribute_name] = {};
      }

      attribute_value = variant.products[prodcode].attributes[attribute_name]
      variant.validFilterValues[attribute_name][attribute_value] = 1;
    }
  });
}

/* Checks if an attribute value is available amongst the current candidates and
* returns true or false accordingly */
variant.isAttributeValueAvailable = function isAttributeValueAvailable(attribute_value_id) {
  var attr = variant.parseAttributeValueId(attribute_value_id);
  var attribute_name = attr.attribute_name;
  var attribute_value = attr.attribute_value;

  if (typeof variant.validFilterValues[attribute_name] == "undefined") {
    return false;
  } else if (typeof variant.validFilterValues[attribute_name][attribute_value] == "undefined") {
    return false;
  } else {
    return true;
  }
}

/* Splits an attribute value id and returns an obj with attribute_name and
* attribute_value */
variant.parseAttributeValueId = function parseAttributeValueId(attribute_value_id) {
  var a = attribute_value_id.match(/^(.+)\.(\d+)$/);

  return {
    attribute_name: a[1],
    attribute_value: a[2]
  };
}

/* Check if user has refined to single product and, if required, entered a customisation. Enable/disable "Add to Cart" as appropriate. Return true if button was activated, false if disabled. */
variant.updateAddToCart = function updateAddToCart() {
  var attract_freight, aholder, spinning;

  if (variant.candidates.length == 1 && (!variant.custom_required || document.getElementById('customtext').value.length > 0)) {
    attract_freight = variant.products[variant.candidates[0]].freight_info;
    const pd_submit = document.getElementById('pd_submit');
    const variant_stock = document.getElementById('variant_stock');
    const result = document.getElementById('result');
    removeClassName(pd_submit, 'call-to-action_discouraged');
    if (pd_submit.myOpentip) {
      pd_submit.myOpentip.deactivate();
    }
    addClassName(pd_submit, 'call-to-action_add');
    if (variant_stock) {
      variant_stock.innerHTML = variant.products[variant.candidates[0]].stock_info;
      variant_stock.style.visibility = 'visible';
    }
    result.value = variant.candidates[0];

    //furniture assembly
    aholder = document.getElementById('assembly_holder');
    if (aholder) {
      spinning = document.createElement('img');
      spinning.src = '/media/images/spin-bg-light.gif';
      spinning.style.width = '20px';
      spinning.style.height = '23px';
      spinning.style.margin = '22px';
      aholder.appendChild(spinning);

      variant.toggleAssemblyOptions(true, variant.candidates[0]);
    }

    const freight_container = document.getElementById('freight_container');
    if (attract_freight && freight_container) {
      freight_container.style.display = "block";
      furnitureFreight(this);
    }

    // Get pricebreaks for chosen product
    variant.getPricebreaks(variant.candidates[0]);

    // Get gateway price if liveprice price is 'N/A'
    variant.vGetGwPrice(variant.candidates[0]);

    return true;
  } else {
    // more than 1 candidate
    const pd_submit = document.getElementById('pd_submit');
    addClassName(pd_submit, 'call-to-action_discouraged');
    if (pd_submit.myOpentip) {
      pd_submit.myOpentip.activate();
    }
    removeClassName(pd_submit, 'call-to-action_add');

    //furniture assembly
    if (document.getElementById('assembly_holder')) {
      variant.toggleAssemblyOptions(false);
    }

    const freight_container = document.getElementById('freight_container');
    if (freight_container) {
      freight_container.style.display = "none";
    }

    const variant_stock = document.getElementById('variant_stock');
    if (variant_stock) {
      variant_stock.style.visibility = 'hidden';
    }

    // Clear pricebreaks
    const vpricebreaks = document.getElementById('vpricebreaks');
    if (vpricebreaks) {
      vpricebreaks.innerHTML = "";
    }
    document.querySelectorAll('.pdp_pricebox .pdp_pricebreaks').forEach(function (elem) {
      elem.parentNode.removeChild(elem);
    });
    document.querySelectorAll('form').forEach(item => {
      if (item.getAttribute('name') == 'blowup') {
        item.querySelectorAll('div.pd_price_box').forEach(pd_price_box => {
          pd_price_box.removeAttribute('data-pricebreaks');
        });
        item.querySelectorAll('div.pdp_pricebox').forEach(pdp_pricebox => {
          pdp_pricebox.removeAttribute('data-pricebreaks');
        });
      }
    });
    watchPriceBreaks();

    return false;
  }
}

variant.vGetGwPrice = function vGetGwPrice(prodcode) {
  if (variant.products[prodcode].price === 'N/A') {
    getGwPrice('json', '', prodcode);
  }
}

variant.processGwPrice = function processGwPrice(data) {
  var gw_prodcode = data.prodcode;
  var gw_price = data.total;

  // Set variant price in products object and dom
  variant.products[gw_prodcode].price = gw_price;
  const candidates_price = document.getElementById('candidates_price');
  if (candidates_price) {
    candidates_price.innerHTML = gw_price;
  }
}

variant.getPricebreaks = function getPricebreaks(prodcode) {
  function bindPriceBreaks(data) {
    var priceBreaksData;
    try {
      // see if our returned data has pricebreaks
      const priceBreaks = document.createElement('div');
      priceBreaks.innerHTML = data.firstChild;
      priceBreaksData = priceBreaks.getAttribute('data-pricebreaks')
        || "";
      //find the price box div
      document.querySelectorAll('form').forEach(item => {
        if (item.getAttribute('name') == 'blowup') {
          item.querySelectorAll('div.pd_price_box').forEach(pd_price_box => {
            pd_price_box.setAttribute('data-pricebreaks', priceBreaksData)
            addClassName(pd_price_box, 'pricebreaks_wrapper');
          });
          item.querySelectorAll('div.pdp_pricebox').forEach(pdp_pricebox => {
            pdp_pricebox.setAttribute('data-pricebreaks', priceBreaksData)
            addClassName(pdp_pricebox, 'pricebreaks_wrapper');
          });
        }
      });
      // add or remove watcher
      watchPriceBreaks();
      //put the pricebreaks in a popup for the experiment
      document.querySelectorAll('.pdp_pricebox .pdp_pricebreaks').forEach(elem => {
        // remove existing, if any
        elem.parentNode.removeChild(elem);
      });
      if (priceBreaksData) {
        document.querySelectorAll('.pdp_pricebox .qty-wrap').forEach(elem => {
          let pbs = '<div class="pdp_pricebreaks"><div class="pdp_pricebreaks-popout">';
          const vpricebreaks = document.getElementById('vpricebreaks');
          if (vpricebreaks) {
            pbs += vpricebreaks.innerHTML + '</div></div>';
          } else {
            pbs += '</div></div>';
          }
          elem.parentNode.insertBefore(pbs, elem.nextSibling);
        });
      }

    } catch (err) { }
  }
  fetch('/main-ajax-pricebreaks?prodcode=' + prodcode, {
    method: 'GET'
  }).then(res => res.text()).then(transport => {
    bindPriceBreaks(transport);
  });
}


/* On the variant product page, the prodcode is now displayed; when selecting
* different variant attributes, the prodcode now is updated and displayed */
variant.updateProductCode = function updateProductCode() {
  //hide any existing etds
  document.querySelectorAll('.etd_box > div').forEach(function (etdDiv) {
    const deliveryInfo = document.querySelectorAll('.delivery-info-hidden')[0];
    if (deliveryInfo.children.length > 0) {
      deliveryInfo.insertBefore(etdDiv, deliveryInfo.firstChild);
    } else {
      deliveryInfo.appendChild(etdDiv);
    }
  });
  if (variant.candidates.length == 1) {
    const codes = document.getElementById('codes');
    if (codes) {
      codes.innerHTML = 'Product code: ' + variant.candidates[0];
    }
    // show the new etd
    document.querySelectorAll('.delivery-info-hidden >div').forEach(etdDiv => {
      if (etdDiv.getAttribute('data-prodcode') == variant.candidates[0]) {
        const etdBox = document.querySelector('.etd_box');
        if (etdBox) {
          etdBox.innerHTML = "";
          etdBox.appendChild(etdDiv);
        }
      }
    });
  } else {
    const codes = document.getElementById('codes');
    if (codes) {
      codes.innerHTML = 'Product code: ';
    }
  }
}

/* Set the current product image to be one of the currently valid candidates,
* also update the price, stock info and enable/disable the "Add to Cart"
* button. Also updates the prodcode for the variant on the page now. */
variant.updateImageAndPrice = function updateImageAndPrice() {
  var i, price, allsame, visiblethumbnails, displayed_mediaid, clk, carouselContainer;
  var mediaids = [];
  variant.updateAddToCart();
  if (variant.displayprices) {
    if (variant.candidates.length == 1) {
      price = variant.products[variant.candidates[0]].price;
    } else if (typeof variant.candidatesMinPrice == "undefined") {
      allsame = true;
      price = variant.products[variant.candidates[0]].price;
      variant.candidates.slice(1).forEach(function (candidate) {
        if (variant.products[candidate].price != price) {
          allsame = false;
        }
      });
      if (!allsame) {
        price = '';
      }
    } else if (variant.candidatesMinPrice == variant.candidatesMaxPrice) {
      price = variant.candidatesMinPrice;
    } else {
      price = '<span class="pdp_price_from">From</span> ' + variant.candidatesMinPrice;
    }
    const candidates_price = document.getElementById('candidates_price');
    if (candidates_price) {
      candidates_price.innerHTML = (price === 'N/A' ? '' : price);
    }
  }
  // Get media ids from current candidates
  variant.candidates.forEach(function (prodcode) {
    variant.products[prodcode].images.forEach(function (imageid) {
      mediaids[imageid] = 1;
    });
  });

  // show/hide thumbnails depends on candidates
  document.querySelectorAll('.display_box').forEach(function (display_box) {
    var a = display_box.id.match(/^(display_box_|video_|image360_)(.+)$/);
    var mediatype = a[1];
    var mediaid = a[2];

    if (mediaids[mediaid] || mediatype == 'video_' || mediatype == 'image360_') {
      removeClassName(display_box, 'display_none');
    } else {
      addClassName(display_box, 'display_none');
    }
  });

  // Get list of thumbnails that are not hidden
  visiblethumbnails = document.querySelectorAll('.display_box:not(.display_none)');

  carouselContainer = document.querySelectorAll('.product_image_selector .carousel')[0];
  if (typeof carouselContainer == 'object' && carouselContainer.imagePickerCarousel) {
    carouselContainer.imagePickerCarousel.resetCarousel();
  }

  variant.updateProductCode();

  // Don't change image if the one being displayed is still a candidatee; check
  // that there is an image displayed to get information for first, this is for
  // the case where a product doesn't have an image to display
  if (document.getElementById('display_image')) {
    displayed_mediaid = document.getElementById('display_image').mediaid;
    if (typeof displayed_mediaid != "undefined" && !document.getElementById(displayed_mediaid).classList.contains('display_none')) {
      return;
    }
  }

  if (visiblethumbnails.length > 0) {
    for (clk = 0; clk < visiblethumbnails.length; clk++) {
      if (visiblethumbnails[clk].id.match(/^display_box_/)) { break; }
    }
    document.getElementById('display_image').mediaid = visiblethumbnails[clk].id;
    visiblethumbnails[clk].onclick();
  }
}

/* Returns a human readable string with instructions of what needs to be fulfilled before "Add to Cart" becomes available */
variant.addToCartMessage = function addToCartMessage() {
  return 'Please select ' + variant.nonRefinedAttributes() + ' to add to cart';
}

/* Returns a human readable string of attributes that have not been selected */
variant.nonRefinedAttributes = function nonRefinedAttributes() {
  var attributes = [];
  document.querySelectorAll('.variant_attribute_name').forEach(function (attribute_name_element) {
    var a, attribute_name;
    if (!attribute_name_element.id) {
      return; /* Skip */
    }
    a = attribute_name_element.id.match(/^attribute_(.+)$/);
    attribute_name = a[1];
    if (!variant.activeFilters[attribute_name]) {
      attributes.push(attribute_name);
    }
  });

  if (variant.custom_required && document.getElementById('customtext').value.length == 0) {
    attributes.push(variant.custom_label);
  }

  if (attributes.length == 1) {
    return attributes[0];
  } else if (attributes.length == 2) {
    return attributes[0] + ' & ' + attributes[1];
  } else if (attributes.length > 2) {
    return attributes.slice(0, attributes.length - 1).join(', ') + ' & ' + attributes[attributes.length - 1];
  } else {
    return '';
  }
}

/* Zooms the currently selected image */
variant.zoomImage = function zoomImage() {
  window.Dialogs.load();
  new Dialog({
    handle: '.zoom_variant_product',
    title: 'Zoomed Image',
    ajax: function () {
      var di = document.getElementById('display_image');
      var dii = di ? di.nx_mi_index ? di.nx_mi_index : 0 : 0;
      var url = '/main-my-zoom/' + di.prodcode + '/' + dii;
      return { url: url };
    }
  });
}

/* Staff page stuff....  */
variant.editValue = function editValue(attrcode, valuetext) {
  Dialogs.load();

  new Dialog({
    title: attrcode + ' &#10132; ' + valuetext,
    autoOpen: true,
    width: 700,
    ajax: function () {
      var url = '/staff-ajax-variantmaterials?action=edit_value&attrcode=' + attrcode + '&valuetext=' + valuetext;
      return {
        url: url,
        options: {
          onComplete: function () {
            window.nx.open_lightbox.setDimensions();
          }
        }
      };
    },
    afterOpen: function (d) {
      window.nx.open_lightbox = d;
    },
    afterClose: function () {
    }
  });
}

variant.moveValueUp = function moveValueUp(id) {
  const el = document.getElementById(id);
  const prevEl = el.previousElementSibling;
  const container = el.parentElement;
  container.insertBefore(el,prevEl);
  variant.prepareSave();
}

variant.prepareSave = function prepareSave() {
  // example field value looks like
  // staffvariant_attribute[]=125&staffvariant_attribute[]=123&staffvariant_attribute[]=126&staffvariant_attribute[]=124
  const container = document.getElementById('staffvariant_attribute');
  const ret=[];
  container.childNodes.forEach(li => {
    ret.push(`staffvariant_attribute[]=${li.getAttribute('data-value')}`);
  });
  document.getElementById('attribute_values_order').value = ret.join('&');
}

variant.editSupplement = function editSupplement(attrcode) {
  Dialogs.load();

  new Dialog({
    title: attrcode,
    autoOpen: true,
    width: 700,
    height: 485,
    close: {
      link: true,
      overlay: false,
      esc: true
    },
    ajax: function () {
      var url = '/staff-ajax-variantmaterials?action=edit_supplement&attrcode=' + attrcode;
      return {
        url: url,
        options: {
          onComplete: function () {
            window.nx.open_lightbox.setDimensions();

            tinymce.init({
              invalid_elements: "tbody",
              plugins: "code table",
              resize: false,
              selector: "#supplement",
              width: 698,
              height: 385,
              statusbar: false,
              menubar: false,
              toolbar: "styleselect | cut copy paste | undo redo | bold italic superscript removeformat | bullist numlist | code | table"
            });

          }
        }
      };
    },
    afterOpen: function (d) {
      window.nx.open_lightbox = d;
    },
    afterClose: function () {
    }
  });
}

variant.showSupplement = function showSupplement(title, info) {
  Dialogs.load();

  new Dialog({
    title: title,
    autoOpen: true,
    content: info,
    afterOpen: function (d) {
      window.nx.open_lightbox = d;
    },
    afterClose: function () {
    }
  });
}

variant.addValues = function addValues(title, contentdiv) {
  Dialogs.load();
  var dialog = new Dialog({
    title: title,
    autoOpen: true,
    width: 700,
    content: document.getElementById(contentdiv).innerHTML
  });
  dialog.setDimensions();
}

variant.toggleAssemblyOptions = function toggleAssemblyOptions(display_mode, assembly_code) {
  let is_checked = '';
  if (display_mode) {
    const assembly_holder = document.getElementById('assembly_holder');
    if (document.querySelectorAll('img.assembly_icon')[0]) {
      is_checked = 'yes';
    }
    fetch('/main-ajax-assemblyoption?prodcode=' + assembly_code + '&is_checked=' + is_checked, {
      method: 'GET'
    }).then(function (res) {
      if (res.ok) {
        return res.text();
      }
      throw new Error('Network response was not ok.');
    }).then(data => {
      if (assembly_holder && assembly_holder.innerHTML != '') { //don't update if it's not a spinner or some content
        assembly_holder.innerHTML = data;
      }
    }).catch(() => {
      variant.toggleAssemblyOptions(false);
      if (assembly_holder) {
        assembly_holder.innerHTML = "";
      }
    });
  } else {
    /* Remove it */
    const assembly_holder = document.getElementById('assembly_holder');
    if (assembly_holder) {
      assembly_holder.innerHTML = "";
    }
  }
}

export { variant };
